<style lang="scss">
@import "~@/assets/css/var";

.dev-table-three {
    &__title {
        font-weight: bold;
        margin: 0 !important;
        padding: 0 20px 15px;
        font-size: 14px;
        position: sticky;
        top: 0;
        z-index: 10;
        background: #fff;
    }

    .ts-table {
        background: #fff;
        padding-right: 10px;
        margin-top: 0 !important;
    }

    .my-table {
        padding: 20px 10px;
    }

    .echart {
        .canvas {
            margin: 10px auto 0;
            height: 500px;
        }
    }
}
</style>

<template>
    <div class="common-table dev-table-three">
        <div class="dev-table-one__title">
            <el-date-picker v-model="date.list" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" @change="changeDate">
            </el-date-picker>
            <el-button class="txt_btn" type="text" icon="el-icon-search" @click="searchShow">筛选</el-button>
        </div>
        <div class="echart" v-loading="loading">
            <div ref="canvas" :id="canvasId" class="canvas" style="width:85vw;"></div>
        </div>
        <div class="ts-table">
            <ts-table class="make-table" style="flex: 3;height: 60vh;" :cols="cols" hide-border :data="list"
                :page-param="pageParam" :loading="loading" hide-detail height="60vh" :cmds="cmds"
                @handleSizeChange="handleSizeChange" name="TsTableThree" @handleSort="sort"
                @handleCurrentChange="handleCurrentChange" :hidePage="false" full zero-to-empty>
                <template #searchLeft>
                    <ts-search-left v-if="isSearchLeft" :searchCols="oddOne" :cols="cols" :isSelectRemote="true"
                        @searchClose="searchClose" @handleFilter="screen"
                        @setselectRemote="selectRemote"></ts-search-left>
                </template>
            </ts-table>
        </div>

    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import variable from "@/util/variable";
export default {
    mixins: [myMixin],
    data() {
        return {
            oddOne: [],
            conditionList: [],
            dialog: {
                show: false,
                cols: [],
                list: [
                    {
                        model: '',
                        field: {}
                    },
                ],
            },
            loading: false,
            stop: true,
            pageParam: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },
            defalutSize: 10,
            currentType: '',
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'referenceNumber',
                    name: '受理号',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 0,
                    listOrderView: '是',
                },
                {
                    fieldName: 'registeredClassification',
                    name: '注册分类',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'comName',
                    name: '药品名称',
                    width: 200,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listOrderView: '是',
                },
                {
                    fieldName: 'compName',
                    name: '企业名称',
                    width: 240,
                    listQueryView: '是',
                    listQueryModel: 1,
                    // listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'undertakeDate',
                    name: '承办日期',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 3,
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'reviewEntryTime',
                    name: '状态开始时间',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 3,
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'taskTypeName',
                    name: '任务类型',
                    width: 150,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc'
                    // listOrderView: '是',
                },
                {
                    fieldName: 'priorApprovalName',
                    name: '优先评审',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'estimatedReviewTime',
                    name: '审评时限',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 0,
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'estimatedAccomplishTime',
                    name: '预计完成时间',
                    width: 100,
                    listQueryView: '是',
                    listQueryModel: 3,
                    listClass: 'tc',
                    listOrderView: '是',
                },

            ]),

            cmds: [],

            rank1: [],
            rank2: [],

            invRow: {},
            drugsNumber: 0,

            comObj: {
                value: '',
                loading: false,
                options: []
            },
            date: {
                list: [new Date(), new Date()]
            },
            canvasId: 'table-three-echart',
            sortParam: {}
        }
    },
    created() {
        // 设置开始日期为前30天,结束日期是当前日期,注意使用$set来设置!
        let startDate = this.getThreeDaysAgo();
        let endDate = this.getToday();
        this.$set(this.date, "list", [startDate, endDate]);
    },

    filters: {
        changeNum(val) {
            let str = val
            if (val == '0') {
                str = '平'
            } else if (val === '--') {
                str = '--'
            } else {
                str = (val * 100).toFixed(2) + '%'
            }
            return str
        }
    },
    methods: {
        //触发下拉框
        selectRemote(item, args, type) {
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }
            this.$set(item, 'loading', true)
            let startDateTime = this.date.list[0].FormatShort()
            let endDateTime = this.date.list[1].FormatShort()
            //字典字段
            let headIdName = ""
            if (item.fieldName == 'priorApprovalName') {           //优先评审
                headIdName = "YES_NO"
            } else if (item.fieldName == 'taskTypeName') {         //任务类型
                headIdName = "REVIEW_TASK_TYPE"
            }
            if (headIdName != "") {
                this.$api.post("sys/sysDictItem/querySysDictItemList", {
                    params: { headId: headIdName },
                })
                    .then((res) => {
                        res.data = res.data.map(orr => {
                            orr.name = item.fieldName
                            orr.value = orr.dataName
                            return orr
                        })
                        this.$set(item, 'list', res.data)
                    })
                    .finally(() => {
                        item.loading = false
                    })
            } else if (item.fieldName == 'comName') {//药品名称
                this.$api.get(`/flow/flowDevelopmentReviewProgress/findNotPassFlowDevelopmentReviewProgressComList?startDate=${startDateTime}&endDate=${endDateTime}&keyword=${args || ''}`)
                    .then(res => {
                        item.list = res.data.map(orr => {
                            orr.name = 'comName'
                            orr.value = orr.comName
                            return orr
                        })
                    })
                    .finally(() => {
                        item.loading = false
                    })
            } else if (item.fieldName == 'compName') { //企业名称
                this.$api.get(`/flow/flowDevelopmentReviewProgress/findNotPassFlowDevelopmentReviewProgressCompList?startDate=${startDateTime}&endDate=${endDateTime}&keyword=${args || ''}`)
                    .then(res => {
                        item.list = res.data.map(orr => {
                            orr.name = 'compName'
                            orr.value = orr.compName
                            return orr
                        })
                    })
                    .finally(() => {
                        item.loading = false
                    })
            } else {
                const str = args ? encodeURIComponent(args) : ''
                const param = this.getParam1()
                delete param.conditionList
                this.$api.post(`setting/sysTableField/distinctFieldValue?tableName=flow_development_review_progress&fieldName=${item.fieldName}&keyword=${str}`, param)
                    .then(res => {
                        this.$set(item, 'list', res.data)
                    })
                    .finally(() => {
                        item.loading = false
                    })
            }
        },
        //筛选确定按钮
        screen(cols) {
            this.conditionList = []
            this.oddOne = cols
            this.oddOne.map(item => {
                if (item.model) {
                    switch (item.field.listQueryModel) {
                        case variable.searchType.LIKE:
                            this.conditionList.push({
                                name: item.field.fieldName,
                                op: 'like',
                                value: item.model,
                            })
                            break;
                        case variable.searchType.SELECT:
                            if (item.model && item.model.length > 0) {
                                this.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'in',
                                    value: item.model,
                                })
                            }
                            break;
                        case variable.searchType.EQ:
                            if (item.model && item.model.length > 0) {
                                this.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'eq',
                                    value: item.model,
                                })
                            }
                            break;
                        default:
                            if (item.model && item.model.length === 2 && !item.model.includes(undefined)) {
                                this.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'ge',
                                    value: item.model[0],
                                })
                                this.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'le',
                                    value: item.model[1]
                                })
                            }
                    }
                }
            })
            this.initCustom()
        },
        //筛选按钮
        searchShow() {
            this.isSearchLeft = true
        },
        // 获取当前日期
        getToday() {
            //获取当前日期
            let myDate = new Date();
            let nowY = myDate.getFullYear();
            let nowM = myDate.getMonth() + 1;
            let nowD = myDate.getDate();
            let endDate =
                nowY +
                "-" +
                (nowM < 10 ? "0" + nowM : nowM) +
                "-" +
                (nowD < 10 ? "0" + nowD : nowD); //当前日期
            return endDate;
        },
        // 获取三十天之前的日期
        getThreeDaysAgo() {
            let myDate = new Date();
            let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30); //最后一个数字30可改，30天的意思
            let lastY = lw.getFullYear();
            let lastM = lw.getMonth() + 1;
            let lastD = lw.getDate();
            let startData =
                lastY +
                "-" +
                (lastM < 10 ? "0" + lastM : lastM) +
                "-" +
                (lastD < 10 ? "0" + lastD : lastD); //三十天之前日期
            return startData;
        },
        initDate() {
            this.getEchartData()
            this.initCustom()
        },

        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },

        changeDate(date) {
            if (date) {
                this.date.list = date;
                this.initDate()
            }
        },
        getEchartData() {
            let param = {
                startDate: this.date.list[0].FormatShort(),
                endDate: this.date.list[1].FormatShort()
            }
            this.$api.get('flow/flowDevelopmentReviewProgress/findNotPassEvaluationNumber', param).then(res => {
                this.$nextTick(() => {
                    this.renderBar(this.canvasId, res.data)
                })
            })
        },
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.initCustom()
        },
        getCustomList() {
            let startDate = this.date.list[0].FormatShort(),
                endDate = this.date.list[1].FormatShort();
            this.loading = true
            this.$api.post(`flow/flowDevelopmentReviewProgress/findNotPassFlowDevelopmentReviewProgress?startDate=${startDate}&endDate=${endDate}`, {
                page: this.pageParam,
                sort: this.sortParam,
                conditionList: this.conditionList,
            }).then(res => {
                // console.log(res)
                // debugger
                if (res.success) {
                    this.pageParam.total = res.data.totalCount
                    this.list = res.data.records
                }
            })
                .finally(() => {
                    this.loading = false
                })
        },

        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },

        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },
        initSendValue(data) {
            this.invRow = data
        },

        renderBar(el, data = []) {
            // console.log(data)
            const elObj = document.getElementById(el)
            let endNum = 0, seriesData = [], xAxisData = [], barData = [];
            if (data.length !== 0) {
                data.forEach(item => {
                    barData.push(item.number)
                    xAxisData.push(item.taskTypeName ? item.taskTypeName : '空')
                })
                seriesData = [{
                    data: barData,
                    type: 'line',
                    barWidth: 25,
                    showAllSymbol: true,
                    symbol: 'circle',
                    symbolSize: 20,
                    label: {
                        show: true,
                        position: 'top',
                        textStyle: {
                            color: '#6684EC',
                        }
                    },
                    itemStyle: {
                        color: "#6684EC",
                        borderColor: "#fff",
                        borderWidth: 3,
                        shadowColor: 'rgba(0, 0, 0, .3)',
                        shadowBlur: 0,
                        shadowOffsetY: 2,
                        shadowOffsetX: 2,
                    },
                    tooltip: {
                        show: false
                    },
                }]
            }

            if (data.length < 10) {
                endNum = 100
            } else if (data.length > 10 && data.length < 30) {
                endNum = 80
            } else if (data.length > 30 && data.length < 50) {
                endNum = 60
            } else {
                endNum = 40
            }
            const myChart = window.echarts.init(elObj)
            myChart.clear()
            let option = {}
            if (data.length === 0) {
                option = {
                    title: {
                        show: true,
                        text: '暂无数据',
                        left: '50%',
                        top: 'center',
                    }
                }
            } else {
                option = {
                    title: {
                        show: true,
                        textStyle: {
                            width: '100%',
                        },
                    },
                    textStyle: { // 字体的样式
                        fontSize: 18,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#6684EC'
                                    }, {
                                        offset: 0.3,
                                        color: '#6684EC',
                                    }, {
                                        offset: 0,
                                        color: '#6684EC'
                                    }],
                                    global: false
                                }
                            },
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        axisLabel: {
                            interval: 0,//横轴信息全部显示
                            // rotate: 30,//-30度角倾斜显示    
                            // fontSize: 15 //设置坐标轴文本标签的字体大小
                        },
                    },
                    yAxis: {
                        type: 'value',

                    },
                    grid: {
                        bottom: '100',
                    },
                    dataZoom: [{
                        type: "slider", // slider 在外部显示  inside 在内部可以横向拖动
                        height: 20,
                        show: true,
                        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
                        // handleIcon: handleIcon,
                        xAxisIndex: [0],
                        start: 0,
                        end: endNum, // 0 - 100 这里是百分比
                        bottom: '20'
                    }],
                    series: seriesData
                }
            }
            myChart.setOption(option, true)
            window.addEventListener('resize', function () {
                myChart.resize()
            })
            return myChart
        },
    },
    mounted() {
        this.initDate()
    }
}
</script>